import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import { Box } from '@chakra-ui/react'
import Welcome from "../components/HomeContent/Welcome";
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Section from '../components/Section/Section';
import OurProjects from "../components/HomeContent/OurProjects";
import OurTeam from "../components/HomeContent/OurTeam";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import WhatWeOffer from "../components/HomeContent/WhatWeOffer";

const IndexPage = ({ data, location }) => {

  const pageContent = data.prismicHomepage.data;
  const projects = [pageContent.project_1, pageContent.project_2, pageContent.project_3];
  
  return (
    <Layout
      footerImage={pageContent.footer_image.localFile}
      location={location}
      heroMobileImage={pageContent.hero_mobile_image.localFile}
      heroMobileImageAlt={pageContent.hero_mobile_image.alt}
      heroPrimaryImage={pageContent.hero_primary_image.localFile}
      heroPortraitImage={pageContent.hero_portrait_image.localFile}
      heroLandscapeImage={pageContent.hero_landscape_image.localFile}
      heroTitle={pageContent.hero_headline}
      heroSubtitle={pageContent.hero_subhead}
    >
      <Welcome 
        welcomeBg={pageContent.welcome_background_image.localFile}
        welcomeBgMobile={pageContent.welcome_background_mobile_image.localFile}
        welcomeHeadline={pageContent.welcome_headline}
        welcomeText={pageContent.welcome_text.richText}
        welcomeLinks={pageContent.welcome_links}
      />
      
        <SliceZone 
          slices={pageContent.body}
          components={components}
        />

        <Section>
          <OurProjects 
            projects={projects}
          />
        </Section>

        <Section>
          <OurTeam 
            teamHeadline={pageContent.our_team_title}
            teamText={pageContent.our_team_text.richText}
            bigImage={pageContent.our_team_big_image.localFile}
            image2={pageContent.our_team_image_2.localFile}
            image3={pageContent.our_team_image_3.localFile}
          />
        </Section>

        <Box mb="24">
          <GatsbyImage image={getImage(pageContent.full_width_image.localFile)} alt={pageContent.full_width_image.alt} />
        </Box>

        <Section>
          <WhatWeOffer 
            title={pageContent.what_we_offer_title.text}
            items={pageContent.what_we_offer}
          />
        </Section>
    </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    data {
      body {
        ... on PrismicHomepageDataBodyContentRelationship {
          id
          slice_type
          primary {
            relationship {
              document {
                ... on PrismicTestimonials {
                  id
                  data {
                    testimonial {
                      testimonial_text {
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      project_1 {
        document {
          ... on PrismicProject {
            uid
            url
            data {
              project_title
              project_images {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5
                        layout: FULL_WIDTH
                        placeholder: TRACED_SVG
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      project_2 {
        document {
          ... on PrismicProject {
            uid
            url
            data {
              project_title
              project_images {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5
                        layout: FULL_WIDTH
                        placeholder: TRACED_SVG
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      project_3 {
        document {
          ... on PrismicProject {
            uid
            url
            data {
              project_title
              project_images {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      hero_button_label
      hero_subhead
      hero_headline
      hero_button_link {
        url
        target
      }
      hero_primary_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
      hero_portrait_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      hero_landscape_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
      welcome_text {
        richText
      }
      welcome_links {
        welcome_link {
          url
          target
        }
        link_label
      }
      welcome_headline
      welcome_background_image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
        alt
      }
      our_team_link {
        url
        target
      }
      our_team_title
      our_team_text {
        richText
      }
      our_team_link_label
      our_team_image_3 {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
      our_team_image_2 {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
      our_team_big_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
      footer_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      welcome_background_mobile_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      what_we_offer_title {
        text
      }
      what_we_offer {
        link_label
        link {
          url
        }
        item_title {
          text
        }
        item_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, layout: FULL_WIDTH)
            }
          }
        }
      }
      full_width_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      hero_mobile_image {
        alt
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`

export default IndexPage

export const Head = () => <title>Cloud 9 Builders | Teton Valley, ID</title>
