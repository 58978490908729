import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Container,
    Box,
    Flex,
    Heading,
    Link,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { PrismicRichText } from '@prismicio/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const StyledGridContainer = styled(Box)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
`
const PrimaryGridItem = styled(Box)`
    grid-area: 1 / 1 / 3 / 3;
`;
const SecondGridItem = styled(Box)`
   grid-area: 1 / 3 / 2 / 4;
`;
const ThirdGridItem = styled(Box)`
   grid-area: 2 / 3 / 3 / 4;
`;

const TeamLink = (props) => (
    <Box px="8" w={{base: "100%", lg: "30%"}} mb="4">
        <Link
            as={GatsbyLink}
            to={props.welcomeButtonLink}
            color="brand.500"
            _hover={{ color: 'brand.200' }}
            fontWeight="900"
        >
            {props.welcomeButtonText}
        </Link>
    </Box>
)

export default function OurTeam(props) {
    return (
        <Container maxW="container.lg" textAlign="center" mb="24">

            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', md: '50%'}} px="4">
                    <StyledGridContainer mb={{base: 8, md: 0}}>
                        <PrimaryGridItem>
                            <GatsbyImage image={getImage(props.bigImage)} alt={props.bigImage.alt}/>
                        </PrimaryGridItem>
                        <SecondGridItem>
                        <GatsbyImage image={getImage(props.image2)} alt={props.image3.alt}/>
                        </SecondGridItem>
                        <ThirdGridItem>
                        <GatsbyImage image={getImage(props.image3)} alt={props.image3.alt}/>
                        </ThirdGridItem>
                    </StyledGridContainer>
                </Box>
                <Box w={{base: '100%', md: '50%'}} px="4">
                    <Heading as="h2" mb="8" color="#4c4c4c" fontWeight="900" textAlign={{base: "center", md: "left"}} fontSize="3rem" fontFamily="Libre Caslon Text, serif">
                        {props.teamHeadline}
                    </Heading>
                    <Box textAlign="left">
                        <PrismicRichText field={props.teamText} />
                    </Box>
                    <Box my="8" textAlign={{base: "center", md: "left"}}>
                        <Link to="/about-us" textAlign={{base: "center", md: "left"}} as={GatsbyLink} color="gold.500" fontWeight="700" textTransform="uppercase" _hover={{textDecoration: 'none', color: 'gold.700'}} >
                            Read More About Our Team <span><ArrowForwardIcon /></span>
                        </Link>
                    </Box>
                </Box>
            </Flex>
            
        </Container>
    )
}