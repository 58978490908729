import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
    Box,
    Heading,
    Flex,
    Link,
    Text,
    LinkBox,
    LinkOverlay
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

export default function OurProjects({ projects }) {

  
  const enlargeTitle = {
    hover: {
        scale: '1.05',
    },
    tap: {
        scale: '.95',
    },
    transition: {
        ease: "easeInOut",
        duration: 2.5,
     }
}

    return (
        <>
            <Heading as="h2" mb="8" color="#4c4c4c" fontWeight="900" textAlign="center" fontSize="3rem" fontFamily="Libre Caslon Text, serif">
                Our Projects
            </Heading>
            <Box textAlign="center" mb="8">
                <Link as={GatsbyLink} to="/our-projects" color="gold.500" fontWeight="700" textTransform="uppercase" _hover={{textDecoration: 'none', color: 'gold.700'}}>
                    View All Of Our Projects <span><ArrowForwardIcon /></span>
                </Link>
            </Box>
            <Flex w="100%" justifyContent="center" flexWrap="wrap">
                {projects.map((project, index) => {
                    return (
                        <>
                            {project.document && (
                            <Box as={motion.div} layout 
                            initial={{opacity: 0}}
                            animate={{ opacity: 1}}
                            exit={{ opacity: 0}}
                            whileHover="hover"
                            whileTap="tap"
                            key={index} flexDirection="column" w={{base: "100%", lg: "30%"}} mb="8" p="2" variants={enlargeTitle} >
                        <LinkBox as={GatsbyLink} to={project.document?.url}>
                                <Box mb="-1">
                                    <GatsbyImage image={getImage(project.document?.data.project_images[0].image.localFile)} alt={project.document?.data.project_images[0].image.alt} objectFit="cover" style={{height: '100%'}} />
                                </Box>
                                <Flex w="100%" justifyContent="center">
                                        <Box bg="black" px="8" textAlign="center" py="2" w="100%">
                                        <LinkOverlay>
                                            <Text color="white" fontSize="1.25rem" fontWeight="900" mb="0">{project.document?.data.project_title}</Text>
                                            <Text color="gold.500" fontWeight="700" textTransform="uppercase" mb="0" fontSize="0.8rem">View Project</Text>
                                        </LinkOverlay>
                                        </Box>
                                </Flex>
                        </LinkBox>
                        </Box>
                        )}
                        </>
                    )
                }
            )}
            </Flex>
        </>
    )
}