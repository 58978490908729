import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import {
    Container,
    Box,
    Flex,
    Heading,
    Link,
    Text
} from '@chakra-ui/react';

const TeamLink = (props) => (
    <Box px="8" w={{base: "100%", lg: "30%"}} mb="4">
        <Link
            as={GatsbyLink}
            to={props.welcomeButtonLink}
            color="brand.500"
            _hover={{ color: 'brand.200' }}
            fontWeight="900"
        >
            {props.welcomeButtonText}
        </Link>
    </Box>
)

export default function WhatWeOffer(props) {
    return (
        <Container maxW="container.lg" textAlign="center" mb="24">
                <Box>
                    <Heading as="h2" mb="8" color="#4c4c4c" fontWeight="900" textAlign="center" fontSize="3rem" fontFamily="Libre Caslon Text, serif">
                        {props.title}
                    </Heading>
                    <Flex w="100%" flexWrap="wrap">
                        {props.items.map((item, index) => (
                            <Box w={{base: '100%', md: '33.33%'}} p="16" key={index}>
                                <Box mb="4">
                                    <GatsbyImage image={getImage(item.item_image.localFile)} alt={item.item_image.alt} />
                                </Box>
                                <Box px="2">
                                    <Text textAlign="center" fontWeight="700" textTransform="uppercase" color="blue.500" fontSize="1.25rem" mb="2">
                                        {item.item_title.text}
                                    </Text>
                                    <Link as={GatsbyLink} to={item.link.url} color="gold.500" fontStyle="italic" fontWeight="700">
                                        {item.link_label}
                                    </Link>
                                </Box>
                            </Box>
                        ))}
                    </Flex>
                </Box>            
        </Container>
    )
}