import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
    Box,
    Flex,
    Heading,
    Link,
    Container,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure
} from '@chakra-ui/react';
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { PrismicRichText } from '@prismicio/react';
import ContactForm from '../../components/ContactForm/ContactForm'

function ProjectModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Box onClick={onOpen}
                cursor="pointer"
                color="brand.500"
                _hover={{ color: 'brand.200' }}
                fontWeight="900"
                bg="none"
                borderRadius="none"
                >
                {props.welcomeButtonText}
            </Box>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Contact Us</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
               <ContactForm />
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
    )
}

const WelcomeLink = (props) => (
    <Box px="8" w={{base: "100%", lg: "30%"}} mb="4">
        {props.welcomeButtonText === "Start A Project With Us" ? (
            <Box>
                <ProjectModal 
                    welcomeButtonText={props.welcomeButtonText}
                />
            </Box>
        )
        : 
        <Link
        as={GatsbyLink}
        to={props.welcomeButtonLink}
        color="brand.500"
        _hover={{ color: 'brand.200' }}
        fontWeight="900"
    >
        {props.welcomeButtonText}
    </Link> 
        }
        
    </Box>
)

export default function Welcome(props) {
    const welcomeBackground = getImage(props.welcomeBg);
    const welcomeBackgroundImage = convertToBgImage(welcomeBackground);

    const welcomeBackgroundMobile = getImage(props.welcomeBgMobile);
    const welcomeBackgroundImageMobile = convertToBgImage(welcomeBackgroundMobile);
    return (
        <>
        <Box as="section" w="100%" display={{base: 'none', md: 'block'}} overflowX="hidden">
            <BackgroundImage
                {...welcomeBackgroundImage}
                preserveStackingContext
                style={{
                    backgroundPosition: 'bottom center',
                    backgroundSize: 'cover',
                    width: '100vw',

                }}
            >
            <Flex h="100%" w="100%" flexDirection="column" justifyContent="flex-start" pt={{base: 28, lg: 48}} pb={{base: "24rem", lg: "50rem", xl: "80rem"}}>
                <Container maxW="container.md" textAlign="center">
                    <Heading as="h2" textAlign="center" color="gold.500" fontFamily="Libre Caslon Text, serif" fontWeight="900" mb="8">
                        {props.welcomeHeadline}
                    </Heading>
                    <PrismicRichText field={props.welcomeText} />
                    <Flex w="100%" justifyContent="center" mt="8" flexWrap="wrap">
                        {props.welcomeLinks && props.welcomeLinks.map((welcomeLink, index) => (
                            <WelcomeLink 
                                key={index}
                                welcomeButtonText={welcomeLink.link_label}
                                welcomeButtonLink={welcomeLink.welcome_link?.url}
                            />
                        ))}
                    </Flex>
                </Container>
            </Flex>
            </BackgroundImage>
        </Box>

<Box as="section" w="100%" display={{base: 'block', md: 'none'}}>
<BackgroundImage
    {...welcomeBackgroundImageMobile}
    preserveStackingContext
    style={{
        backgroundPosition: 'bottom center',
        backgroundSize: 'contain',
        width: '100vw',

    }}
>
<Flex h="100%" w="100%" flexDirection="column" justifyContent="flex-start" pt={{base: 28, lg: 48}} pb={{base: "24rem", lg: "50rem"}}>
    <Container maxW="container.md" textAlign="center">
        <Heading as="h2" textAlign="center" color="gold.500"  fontWeight="900" mb="8" fontFamily="Libre Caslon Text, serif">
            {props.welcomeHeadline}
        </Heading>
        <PrismicRichText field={props.welcomeText} />
        <Flex w="100%" justifyContent="center" mt="8" flexWrap="wrap">
            {props.welcomeLinks && props.welcomeLinks.map((welcomeLink, index) => (
                <WelcomeLink 
                    welcomeButtonText={welcomeLink.link_label}
                    welcomeButtonLink={welcomeLink.welcome_link?.url}
                    key={index}
                />
            ))}
        </Flex>
    </Container>
</Flex>
</BackgroundImage>
</Box>
</>
    )
}